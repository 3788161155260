import { api } from '@converse/headless';
import { CustomElement } from './element.js';
import { html } from 'lit';


export class ConverseBrandLogo extends CustomElement {

    render () { // eslint-disable-line class-methods-use-this
        const is_fullscreen = api.settings.get('view_mode') === 'fullscreen';
        return html`<span class="brand-name-wrapper ${is_fullscreen ? 'brand-name-wrapper--fullscreen' : ''}"><img src="/dist/images/logo.svg" style="width:90%"><br><span class="brand-name"><span class="brand-name__text">im-changing<span class="subdued">.ru</span></span> ${is_fullscreen
                            ? html`<p class="byline">messaging freedom</p>`
                            : ''}</span></span>`;
    }
}

api.elements.define('converse-brand-logo', ConverseBrandLogo);
